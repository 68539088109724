<template>
    <v-navigation-drawer v-model="drawerShow" :clipped="$vuetify.breakpoint.smAndDown"
                         :mini-variant="mini" :mini-variant.sync="isMini"
                         :expand-on-hover="mini" mobile-breakpoint="960"
                         width="250" mini-variant-width="80"
                         height="100%" light app floating>
        <v-list class="py-0 comany-logo" color="primary"
                dark style="
                border: 0;
                border-radius: 0;
                height: 64px;
                background: #6680b0 !important;
            ">
            <v-list-item>
                <v-list-item-content v-if="($vuetify.breakpoint.smAndDown && mini) || isMini">
                    <v-img src="/img/logo-small.png" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-content v-else>
                    <v-img src="/img/logo.png" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn small icon @click.stop="navToggle" class="mx-0">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider class="my-0"></v-divider>
        <scrollbar ref="scrollbar" :swicher="!$vuetify.breakpoint.smAndDown"
                   :settings="settingsScrollbar" class="inner-scrollbar">
            <v-list class="py-0 section_flex" height="100%" v-for="(group, index) in items" :key="index">
                <template v-for="item in group">
                    <v-subheader v-if="item.header" v-show="item.visible">
                        {{ item.title }}
                    </v-subheader>
                    <v-list-group v-else-if="!!item.items" v-show="item.visible"
                                  :prepend-icon="item.icon" no-action
                                  :key="item.title" v-model="item.active">
                        <template v-slot:activator>
                            <v-list-item-content class="nav-bar-content">
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="subItem in item.items" :key="subItem.title"
                                     v-show="subItem.visible"
                                     @click.native="subItem.action ? subItem.action() : false"
                                     :to="subItem.to" ripple
                                     :exact="subItem.exact !== undefined ? subItem.exact : true"
                                     color="#ffffff" dark>
                            <v-list-item-icon>
                                <v-icon>{{ subItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pl-2 nav-bar-content">
                                <v-list-item-title class="text--white">
                                    {{ subItem.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else v-show="item.visible"
                                 @click.native="item.action ? item.action() : false"
                                 href="javascript:void(0)" :to="item.to" ripple
                                 :exact="item.exact !== undefined ? item.exact : true"
                                 :key="item.title" color="#ffffff" dark class="list-item"
                                 exact-active-class="list-item-active">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="nav-bar-content">
                            <v-list-item-title>
                                {{item.title}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </scrollbar>
    </v-navigation-drawer>
</template>
<script>
import Scrollbar from "./Scrollbar";

export default {
    name: "AppSidebar",
    components: {
        Scrollbar,
    },
    inject: ["forceRerender"],
    props: {
        mini: Boolean,
        drawer: Boolean,
    },
    data() {
        return {
            isMini: null,
            items: [],
            settingsScrollbar: {
                suppressScrollX: true,
            },
        };
    },
    computed: {
        drawerShow: {
            get: function () {
                return this.drawer;
            },
            set: function (state) {
                if (state !== this.drawer) {
                    this.navToggle();
                }
            },
        },
        expandOnHover: function () {
            return this.mini;
        },
        routePath() {
            return this.$route.path;
        },
    },
    watch: {
        routePath(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.navigation();
            }
        },
    },
    mounted() {
        this.navigation();
    },
    methods: {
        navToggle() {
            this.$emit("nav-toggle");
        },
        navMiniToggle() {
            this.$emit("nav-mini-toggle");
        },
        navigation() {
            this.items = [
                [
                    {
                        title: this.$t("menu_wagon_tracking"),
                        icon: "mdi-transit-connection-variant",
                        to: {name: "wagon_tracking"},
                        action: this.forceRerender,
                        visible: this.can(["client"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_arriving_wagons"),
                        icon: "mdi-checkbox-marked-outline",
                        to: {name: "application"},
                        action: this.forceRerender,
                        visible: this.can(["client"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_tracking_container"),
                        icon: "mdi-train-variant ",
                        to: {name: "tracking_container"},
                        action: this.forceRerender,
                        visible: this.can(["client"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_tracking"),
                        icon: "mdi-arrow-decision",
                        to: {name: "tracking"},
                        action: this.forceRerender,
                        visible: this.can(["client"]),
                        exact: false,
                    },
                    {
                        title: this.$t("menu_profile"),
                        icon: "mdi-account-box-outline",
                        to: {name: "profile"},
                        action: this.forceRerender,
                        visible: this.can(["client"]),
                        exact: false,
                    },
                ],
            ];
        },
    },
};
</script>
<style>
div.section_flex {
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //width: auto;
    //flex-wrap: wrap;
}

a.list-item {
    background-color: #45649f !important;
}

a.list-item::after {
    background-color: #45649f !important;
}

a.list-item::before {
    background-color: #45649f !important;
}
</style>
