import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter);

// eslint-disable-next-line no-sparse-arrays
let routes = [
    {
        path: '/',
        redirect: '/wagon_tracking',
    },
    {
        path: '/application',
        name: 'application',
        meta: {layout: 'main', auth: {roles: ['client']}},
        component: () => import(/* webpackChunkName: "clients" */ '../views/Applications.vue'),
    },
    {
        path: '/wagon_tracking',
        name: 'wagon_tracking',
        meta: {layout: 'main', auth: {roles: ['client']}},
        component: () => import(/* webpackChunkName: "clients" */ '../views/WagonTracking.vue'),
    },
    {
        path: '/tracking',
        name: 'tracking',
        meta: {layout: 'main', auth: {roles: ['client']}},
        component: () => import(/* webpackChunkName: "tracks" */ '../views/Tracking.vue'),
    },
    {
        path: '/tracking_container',
        name: 'tracking_container',
        meta: {layout: 'main', auth: {roles: ['client']}},
        component: () => import(/* webpackChunkName: "tracks" */ '../views/TrackingContainers.vue'),
    },
    {
        path: '/tracking_container/:tracking_id',
        name: 'tracking_container_form',
        meta: {layout: 'main', auth: { roles: ['client'] }},
        component: () => import(/* webpackChunkName: "tracking_container_form" */'../views/TrackingContainerForm.vue'),
    },
    {
        path: '/profile',
        name: 'profile',
        meta: {layout: 'main', auth: {roles: ['client']}},
        component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileForm.vue'),
    },
    {
        path: '/company',
        name: 'company',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "companies" */ '../views/Companies.vue'),
    },
    {
        path: '/company/create',
        name: 'company.create',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "company-form" */ '../views/CompanyForm.vue'),
    },
    {
        path: '/company/:id/edit',
        name: 'company.edit',
        meta: {layout: 'main', auth: {roles: ['administrator']}},
        component: () => import(/* webpackChunkName: "company-form" */ '../views/CompanyForm.vue'),
    },
    {
        path: '/mailing/:mailing/:uuid',
        name: 'mailing',
        meta: {layout: 'single-page', auth: undefined},
        component: () => import(/* webpackChunkName: "mailing" */ '../views/Mailing.vue'),
    },
    {
        path: '/auth/login',
        name: 'login',
        meta: { layout: 'login-page', auth: false },
        component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue'),
    },
    {
        path: '/auth/register',
        name: 'register',
        meta: {layout: 'login-page', auth: false},
        component: () => import(/* webpackChunkName: "register" */ '../views/Auth/Login.vue'),
    },
    {
        path: '/auth/forgot',
        name: 'forgot',
        meta: {layout: 'login-page', auth: false},
        component: () => import(/* webpackChunkName: "forgot" */ '../views/Auth/Forgot.vue'),
    },,
    {
        path: '/auth/forgot/check',
        name: 'reset_password',
        meta: {layout: 'login-page', auth: false},
        component: () => import(/* webpackChunkName: "reset-password" */ '../views/Auth/ResetPassword.vue'),
    },
    {
        path: '/404',
        name: 'error-404',
        meta: {layout: 'main', auth: true},
        component: () => import(/* webpackChunkName: "not-found" */ '../views/Errors/NotFound.vue'),
    },
    {
        path: '/403',
        name: 'error-403',
        meta: {layout: 'main', auth: true},
        component: () => import(/* webpackChunkName: "forbidden" */ '../views/Errors/Forbidden.vue'),
    },
    {
        path: '/block',
        name: 'block',
        meta: {layout: 'single-page', auth: undefined},
        component: () => import(/* webpackChunkName: "blocked" */ '../views/Errors/Blocked.vue'),
    },
];


const router = new VueRouter({
    scrollBehavior() {
        return window.scrollTo({top: 0, behavior: 'smooth'});
    },
    hashbang: false,
    linkActiveClass: 'active',
    transitionOnLoad: true,
    mode: 'history',
    base: __dirname,
    routes,
});

export default router;
